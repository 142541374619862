<template>
  <div class="contact">
    <div class="title"></div>
    <!-- <div class="qrcode">
      <div class="code1">
        <img class="qrcode_kf" src="../../assets/images/mpp/qrcode_kf.png" alt="taptap">
      </div>
      <div class="code2">
        <img class="qrcode_wxgzh" src="../../assets/images/mpp/qrcode_wxgzh.jpg" alt="taptap">
      </div>
    </div> -->
    <div class="third">
      <div class="girl1"> </div>
      <div id="wxdiv">
        <img class="taptap" src="../../assets/images/mpp/icon_wx.png" alt="微信" @click="clickTap">
        <img class="wxq" src="../../assets/images/mpp/gn_wxgzh.png">
      </div>
      <div class="girl2"> </div>
      <img class="dy" src="../../assets/images/mpp/icon_dy.png" alt="抖音号" @click="clickDy">
      <div class="girl3"> </div>
      <img class="wb" src="../../assets/images/mpp/icon_qq.png" alt="qq" @click="clickWb">
    </div>
  </div>
</template>

<script>
export default {
  name: "Home_Contact",
  data() {
    return {
      IsCN: process.env.VUE_APP_I18N == 'CN'
    };
  },
  methods: {
    clickTap() {
      window.open("https://www.taptap.com/app/233061", '_blank')
    },
    clickDy() {
      // window.open("https://www.douyin.com/user/MS4wLjABAAAASwPWVjc2tVF5VwEQTetpJLiE717-O7-w92LakCUG1phwcvKm_T6JJhm5IVrpjMKw", '_blank')
      window.open("https://v.douyin.com/iejTU7jq/", '_blank')
    },
    clickWb() {
      window.open("http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=1beMhb0OqzwzBWSM6-LEmD2ulfva0yM3&authKey=9hyFFHGr2zKBF1474oC%2FJmDczwlYdvmso4%2BVhPLG8y0W%2FpWjwLl2lLxAfpzNJf74&noverify=0&group_code=260826449", '_blank')
    }
  }
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  user-select: none
}

.contact {
  height: 1570px;
  background: url("../../assets/images/mpp/p4_bg.jpg") center center no-repeat;
  overflow: hidden;

  .title {
    background: url("../../assets/images/mpp/p4_title.png") center center no-repeat;
    position: relative;
    top: 50px;
    width: 579px;
    height: 96px;
    left: 35%;
  }

  .qrcode {
    margin-top: 100px;
    width: 800px;
    height: 100px;
    display: flex;

    .code1 {
      background: url("../../assets/images/mpp/icon_qrcode.png") center center no-repeat;
      width: 367px;
      height: 417px;
      position: relative;
      left: 400px;
    }

    .qrcode_kf {
      width: 56%;
      top: 28%;
      left: 13%;
      position: relative;
    }

    .qrcode_wxgzh {
      width: 56%;
      top: 28%;
      left: 13%;
      position: relative;
    }

    .code2 {
      background: url("../../assets/images/mpp/icon_qrcode.png") center center no-repeat;
      height: 417px;
      width: 367px;
      position: relative;
      left: 800px;
    }
  }

  .third {
    margin-top: 120px;
    display: flex;

    .girl1 {
      background: url("../../assets/images/mpp/p4_girl1.png") no-repeat;
      position: relative;
      width: 163px;
      height: 246px;
      left: 15%;
    }

    #wxdiv {
      position: relative;
      left: 15%;
    }

    #wxdiv:hover .wxq {
      position: absolute;
      top: -50%;
      left: -50%;
      display: flex;
      z-index: 999999;
    }

    .wxq {
      display: none
    }

    .taptap {
      position: relative;
      left: 15%;
    }


    .girl2 {
      background: url("../../assets/images/mpp/p4_girl2.png");
      position: relative;
      width: 163px;
      height: 246px;
      left: 20%;
    }

    .dy {
      position: relative;
      left: 20%;
    }

    .girl3 {
      background: url("../../assets/images/mpp/p4_girl3.png");
      position: relative;
      width: 163px;
      height: 246px;
      left: 25%;
    }


    .wb {
      position: relative;
      left: 25%;
    }
  }
}
</style>