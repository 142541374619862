<template>
  <div class="footer" id="p4">
    <div class="copyRight">
      <div class="copyRightLeft">
        <div class="copyRightRight">
          <div class="copyRightRightTop">
            <a v-for="( item, index ) in  Links " :key=index :href=item.href target=“_blank”>
              {{ item.name }}
              {{ index < 3 ? "|" : "" }} </a>
          </div>
          <div class="copyRightRightCenter">
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target=“_blank”
              style="color: white;">琼ICP备19004251号-3</a> <br>
            Copyright © 2023.星云猫工作室 版权所有<br>
            商务合作：cooperate@nebulacat.com
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lan from "../assets/lan.json"
const lans = lan[process.env.VUE_APP_I18N].Footer
export default {
  name: "compFooter",
  data() {
    return {
      Links: lans.Links,
      BottomTipsImg: [
        require('../assets/images/Footer/icon1.png'), require('../assets/images/Footer/icon2.png'),
        require('../assets/images/Footer/icon3.png'), require('../assets/images/Footer/icon4.png'),
        require('../assets/images/Footer/icon5.png'), require('../assets/images/Footer/icon6.png'),
      ]
    }
  }
}
</script>

<style scoped>
.footer {
  position: relative;
  /* display: inline-block; */
  /* width: 100%; */
  height: 30px;
  /* min-width: 1900px; */
  background-color: black;
  left: -1px;

  margin: 0 auto;
  min-width: 1900px;
  max-width: 1920px;
}

.footer-list-bg {
  position: relative;
  top: 0px;
  display: inline-block;
  width: 100%;
  height: 52px;
  background-color: #111111;
  border-bottom: #1a1a1a solid 2px;
}

.footer .footerList {
  display: flex;
  z-index: 2;
  list-style-type: none;
  width: 100%;
  height: 52px;
  line-height: 52px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-bottom: #1a1a1a solid 2px;
}

.footer li {
  position: relative;
  float: left;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-right: 34px;
  opacity: 0.4;
  cursor: pointer;
  background-size: cover;
}

.footer li:hover {
  opacity: 1;
}

.copyRight {
  position: absolute;
  /* left: 30%; */
  /* transform: translateX(-50%); */
  display: inline-block;
  width: 100%;
  /*height: 100%;*/
  padding-top: 30px;
  background-color: black;
  box-sizing: border-box;
}

.copyRightLeft {
  position: relative;
  /* left: 16.4%; */
  display: inline-block;
  width: 300px;
  margin-right: 10px;
}

.copyRightLeftLogo {
  position: absolute;
  top: 90.55px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  width: 280.4px;
  height: 87.7px;
}

.copyRightLeftLogo span {
  width: 1px;
  height: 28px;
  background: #666;
  margin-left: 25px;
  margin-right: 25px;
}

.copyRightLeftLogo div {
  display: flex;
  left: 50%;
  align-items: center;
}

.copyRightRight {
  position: relative;
  left: 120%;
  display: inline-block;
  width: 882px;
}

.copyRightRightTop {
  display: inline-block;
  width: 882px;
  height: 48px;
  color: white;
  font-size: 14px;
}

.copyRightRightTop a {
  color: white;
  font-size: 14px;
  text-decoration: none;
}

.copyRightRightCenter {
  display: inline-block;
  width: 882px;
  height: 100px;
  color: #AAAAAA;
  font-size: 14px;
  line-height: 24px;
}

.copyRightRightBottom {
  display: inline-block;
  width: 882px;
  height: 76px;
  margin-top: 10px;
}

.copyRightRightBottom a {
  margin-right: 15px;
}
</style>