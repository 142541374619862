<template>
  <div class="information">
    <div class="title"></div>
    <div class="con-bg"></div>
    <div class="swiper-bg">
      <div class="swiper-container">
        <swiper :modules="modules" :speed="1200" :slides-per-view="1" :space-between="0"
          :autoplay="{ delay: 3000, disableOnInteraction: false }" effect="fade" :pagination="{ clickable: true }"
          navigation>
          <swiper-slide>
            <img src="../../assets/images/mpp/lb_1.jpg" @click="NewsJump(url)" />
          </swiper-slide>
          <swiper-slide>
            <img src="../../assets/images/mpp/lb_2.jpg" @click="NewsJump(url)" />
          </swiper-slide>
          <swiper-slide>
            <img src="../../assets/images/mpp/lb_3.jpg" @click="NewsJump(url)" />
          </swiper-slide>
          <swiper-slide>
            <img src="../../assets/images/mpp/lb_4.jpg" @click="NewsJump(url)" />
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <div class="zxhd-news">
      <div class="news-tab">
        <div class="news-a" v-for="(tab, index) in NewsTab" :key="index" :class="{ on: newsSelect == index }"
          @click="clickNewsTab(tab, index)">
          <span>{{ tab }}</span>
        </div>
      </div>

      <div class="news-con">
        <div class="news-ul news2 on" v-for="(news, index) in selectList" :key="index" @click="JumpToDetail(news._id)">
          <a href="javascript:;">
            <span class="type">{{ _changeTabName(news.type) }}</span>
            <p class="ti">{{ news.title }}</p>
            <p class="time">{{ news.dateStr.slice(5) }}</p>
          </a>
          <div class="line"></div>
        </div>
      </div>

      <div class="news_sk"></div>
      <a class="news_more" href="javascript:;" @click="More"></a>
    </div>


  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, Navigation } from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { request, changeTabName, changeTabNameBack } from '../../request/request';
import lan from "../../assets/lan.json"
const lans = lan[process.env.VUE_APP_I18N].News
export default {
  name: "home_News",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Autoplay, Pagination, Navigation],
      NewsTab: lans.NewsTab,
      newsSelect: 0,
      allNews: [],
      selectList: []
    }
  },

  methods: {
    _changeTabName(tab) {
      return changeTabName(tab)
    },
    clickNewsTab(tab, index) {
      console.log(process.env)
      this.newsSelect = index
      tab = changeTabNameBack(tab)
      this.selectList = this.allNews[tab]
      // if (tab === "全部") {
      //   this.selectList = this.allNews[tab]
      //   return
      // }
      // this.selectList = []
      // this.allNews.map(news => {
      //   if (this.selectList.length >= 6) return
      //   if (news.type === tab) this.selectList.push(news)
      // })
    },

    JumpToDetail(_id) {
      this.$router.push({ path: '/news_detail', query: { _id } })
    },
    More() {
      this.$router.push({ path: '/news_list' })
    }

  },
  mounted() {
    request({
      url: "/gw/getNews?platform=PC&page=home",
      methods: "get",
    }).then(res => {
      if (res.data.code === 200) {
        const { list } = res.data.data
        this.allNews = list
        this.selectList = this.allNews['全部']

      }
    })


  },
}
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  user-select: none
}

.information {
  text-align: center;
  height: 975px;
  background: url("../../assets/images/mpp/p2_bg.png") center center no-repeat;
  overflow: hidden;

  .title {
    background: url("../../assets/images/mpp/p2_title.png");
    position: relative;
    top: 50px;
    width: 579px;
    height: 96px;
    left: 35%;
  }

  .con-bg {
    background: url("../../assets/images/mpp/p2_con_bg.png") no-repeat;
    position: relative;
    width: 1308px;
    height: 675px;
    top: 100px;
    left: 15%;
  }

  .swiper-bg {
    background: url("../../assets/images/mpp/p2_swiper_bg.png") no-repeat;
    position: relative;
    left: 19%;
    width: 678px;
    height: 470px;
    top: -500px;
  }
}

.zxhd-news {
  width: 500px;
  height: 380px;
  position: relative;
  top: -950px;
  left: 53%;
}

.news-tab {
  overflow: hidden;
  height: 50px;
  padding-bottom: 20px;
  width: 600px;
  margin-left: 30px;
}

.news-a {
  float: left;
  width: 90px;
  height: 42px;
  font-size: 30px;
  text-align: center;
  background-color: #c3dbd3;
  border-radius: 20px;
  margin-left: 10px;
  color: #586d66;
}

.news-a.on {
  color: #755b1c;
  background-color: #ffdb4c;

}

.news-con {
  text-align: -webkit-left;
  margin-left: 50px;
}

.news-ul {
  margin-top: 30px;

  .type {
    background-color: #c0e4e6;
    width: 60px;
    border-radius: 20px;
    text-align: center;
    margin-bottom: 5px
  }

  .line {
    background: url("../../assets/images/mpp/p2_line.png");
    position: relative;
    height: 9px;
    width: 460px;
  }

  on {
    display: block;
  }

  a {
    display: flex;
  }

  .ti {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 320px;
    margin-left: 10px;
  }
}

.news_more {
  background: url("../../assets/images/mpp/p2_more.png");
  display: block;
  width: 190px;
  height: 64px;
  position: absolute;
  top: -118px;
  margin-left: 280px;
}

.news_sk {
  background: url("../../assets/images/mpp/p2_sk.png");
  display: block;
  width: 199px;
  height: 244px;
  position: absolute;
  top: -290px;
  margin-left: -680px;
}


.swiper-container {
  position: relative;
  top: 40px;
  width: 640px;
  height: 400px;
  margin-left: 20px;
}

.swiper:deep() {
  .swiper-slide {
    height: 380px;
    line-height: 300px;
    font-size: 30px;
    text-align: center;
  }

  .swiper-pagination {
    position: relative;
    margin-top: 50px;
    margin-left: 240px;

    .swiper-pagination-bullet {
      width: 32px;
      height: 32px;
      background: url("../../assets/images/mpp/p2_bullet.png") no-repeat;
    }

    .swiper-pagination-bullet-active {
      width: 32px;
      height: 32px;
      background: url("../../assets/images/mpp/p2_bullet_active.png") no-repeat;
    }
  }

  .swiper-button-prev {
    background: url("../../assets/images/mpp/p2_button_prev.png") no-repeat;
    position: relative;
    height: 47px;
    width: 32px;
  }

  .swiper-button-next {
    background: url("../../assets/images/mpp/p2_button_next.png") no-repeat;
    position: relative;
    left: 60px;
    margin-top: -47px;
    height: 47px;
    width: 32px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 80px;

    &::after {
      font-size: 0px;
      color: white;
    }
  }

  .swiper-button-disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  .news2 a {
    display: block;
    width: 430px;
    overflow: hidden;
    padding-left: 10px;
    position: relative;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #b5ecdb;
  }

  .news2 a span,
  .news2 a p {
    display: block;
    float: left;
    color: #88656c;
  }

  .news2 a .ti {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 330px;
  }


}
</style>