import { createWebHashHistory, createRouter } from "vue-router";
import mppHome from "@/views/home/home"
import news_List from "@/views/news/news_list"
import news_Detail from "@/views/news/news_detail"


const routes = [
    {
        path: "/",
        name: "Home",
        component: mppHome,
    },
    {
        path: '/news_list', component: news_List
    },
    {
        path: '/news_detail', component: news_Detail
    }
    // {
    //     path: '/home', component: home
    // }, {
    //     path: '/news', component: news
    // }, {
    //     path: '/newsContent/:listItem/:Content',
    //     name: 'newsContent',
    //     component: newsDetail
    // }, {
    //     path: '/role', component: role
    // }, {
    //     path: '/roledetail', component: roleDetail
    // }, {
    //     path: '/wallpaper', component: wallpaper
    // }, {
    //     path: '/imagePage', component: image
    // }
]



const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router