<template>
  <div class="Container" :style="{ width: screenHeight + 'px' }">
    <Header></Header>
    <HomeNews></HomeNews>
    <HomeFeatures></HomeFeatures>
    <Contact></Contact>
    <HomePop></HomePop>
  </div>
</template>

<script>
import Header from "./HOME_Header";
import Contact from "./HOME_Contact"
import HomeNews from "./HOME_News";
import HomeFeatures from "./HOME_Features";
import HomePop from "./HOME_Pop";

export default {
  name: "mppHome",
  components: { Header, Contact, HomeNews, HomeFeatures, HomePop },
  data() {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  watch: {
    screenHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenHeight = val
        this.timer = true
        let that = this
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.screenHeight)
          that.timer = false
        }, 400)
      }
    }
  }

}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none
}

.Container {
  position: relative;
  margin: 0 auto;
  min-width: 1900px;
  max-width: 1920px;
  overflow: hidden
}
</style>