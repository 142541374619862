<template>
  <div class="pop">
    <div class="pop_bg" id="pop_age">
      <div class="age_ti"></div>
      <img src="../../assets/images/pop/closed.png" alt="" class="close" @click="closePopAge">
      <p>&nbsp;&nbsp;1)、本游戏适用于年满8周岁及以上的用户，建议未成年人在家长监护下使用游戏产品。</p>
      <p>&nbsp;&nbsp;2)、本游戏画面色彩多样，画风卡通。游戏内配乐丰富，含有极具特色的配乐及音效。游戏内动作画面元素为虚构内容，不会与现实生活相混淆。</p>
      <p>&nbsp;&nbsp;3)、根据国家相关要求，游戏中有用户实名认证系统，未通过实名认证的用户不可进入游戏；认证为未成年人的用户除周五、周六、
        周日及法定节假日每日20时至21时外其他时间均不可进入游戏。游戏中无内购相关内容，不需要产生额外游戏费用。 </p>
      <p>&nbsp;&nbsp;4)、本游戏皆为玩家在游戏中体验生活乐趣，感受游戏快乐。</p>
    </div>

    <div class="pop_bg_wx" id="pop_wx">
      <div class="wx_ti"></div>
      <img src="../../assets/images/pop/closed.png" alt="" class="close_wx" @click="closePopWx">
      <div class="wx_qrcode"></div>
      <a class="wx_button_top" href="javascript:history.back(-1);" @click="jumpToPhone"> 微信扫描下方二维码预约 </a>
    </div>

    <div class="pop_bg" id="pop_game">
      <div class="game_ti"></div>
      <img src="../../assets/images/pop/closed.png" alt="" class="close" @click="closePopGame">

      <!-- <div class="btn_warp">
        <div class="btnItem" v-for="(tab, index) in ['IOS', 'Android' ]" :key=index
          :class=" {btnItems_Select:btnSelect==index}" @click="BtnLink(index)">
          <div class="tab">{{ tab }}</div>
        </div>
      </div> -->

      <img src="../../assets/images/pop/line.png" alt="" class="line">

      <div class="phone">
        <div class="phone_zi">手机号：</div>
        <input id="phoneNumber" type="number" class="phone_input" placeholder="请输入手机号">
      </div>
      <br />
      <div class="phone">
        <div class="phone_zi">验证码：</div>
        <input id="verifyCode" type="text" class="phone_input" style="width: 300px" placeholder="验证码"
          @change="inputCode">
        <img src="../../assets/images/pop/btn_send.png" alt="" @click="sendVerifyCode">
      </div>
    </div>

  </div>
  <div class="overlay" id="_overlay_"></div>


</template>

<script>
export default {
  name: "home_Pop",
  data() {
    return {
      btnArr: ["IOS", "Android"],
      btnSelect: 0
    }
  },
  methods: {
    inputCode() {
      console.log("inputCode--------->>>>")
    },
    sendVerifyCode() {
      let phone = document.getElementById("phoneNumber").value
      console.log("sendVerifyCode--------->>>>", phone)
    },
    jumpToPhone() {
      // let popSty = document.getElementById('pop_wx')
      // popSty.style.display = "none";
      // let pop_game = document.getElementById('pop_game')
      // pop_game.style.display = "block";
    },
    closePopAge() {
      let overlay = document.getElementById('_overlay_')
      overlay.style.display = "none"
      let popSty = document.getElementById('pop_age')
      popSty.style.display = "none";

    },
    closePopWx() {
      let overlay = document.getElementById('_overlay_')
      overlay.style.display = "none"
      let popSty = document.getElementById('pop_wx')
      popSty.style.display = "none";
    },
    closePopGame() {
      let overlay = document.getElementById('_overlay_')
      overlay.style.display = "none"
      let popSty = document.getElementById('pop_game')
      popSty.style.display = "none";
    },
    BtnLink(index) {
      this.btnSelect = index
      console.log("index:", index)
    },

    // closePopVideo(id) {
    //   console.log("closePopVideo:", id)
    //   // let overlay = document.getElementById('_overlay_')
    //   // overlay.style.display = "none"
    //   // let pop_video = document.getElementById('pop_video' + id)
    //   // pop_video.style.display = "none";

    //   var video = document.getElementById("video" + id);
    //   console.log("video:", video)
    //   console.log("paused:", video.paused)
    //   video.pause()
    // }


  },
  mounted() {

  },
}
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  user-select: none
}

.overlay {
  background-color: rgb(17, 17, 17);
  border-top: 1px solid rgb(17, 17, 17);
  position: absolute;
  height: 7081px;
  z-index: 998;
  width: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.7;
  display: none;
}

.pop {
  .pop_bg {
    display: none;

    background: url("../../assets/images/pop/pop_bg.png") no-repeat center;
    position: fixed;
    width: 942px;
    height: 703px;
    top: 150px;
    left: 25%;
    z-index: 999;

    .age_ti {
      background: url("../../assets/images/pop/age_ti.png") no-repeat;
      position: absolute;
      width: 187px;
      height: 65px;
      top: 22px;
      left: 60px;
      z-index: 999;
    }

    .close {
      position: absolute;
      width: 6.6%;
      height: 8.6%;
      top: 13.8%;
      left: 95.5%;
      z-index: 999;
    }

    p {
      position: relative;
      top: 130px;
      left: 100px;
      width: 740px;
      font-size: 30px;
      text-align: justify;
    }



    .wx_top_p {
      position: relative;
      font-size: 30;
      color: #516f6d;
      top: 150px;
      text-align: center;
    }

    .wx_button_b {
      position: relative;
      color: #516f6d;
      top: 150px;
      border-bottom: 1px solid;
      left: 400px;
    }

    .game_ti {
      background: url("../../assets/images/pop/game_ti.png") no-repeat center;
      position: absolute;
      width: 187px;
      height: 65px;
      top: 22px;
      left: 60px;
      z-index: 999;
    }

    .btn_warp {
      background: url("../../assets/images/pop/game_btn_bg.png") no-repeat center;
      display: flex;
      height: 75px;
      top: 150px;
      position: relative;
    }

    .btnItem {
      position: relative;
      left: 178px;
      width: 300px;
    }

  }

  .pop_bg_wx {
    display: none;

    background: url("../../assets/images/pop/dk_wxyy.png") no-repeat center;
    position: fixed;
    width: 942px;
    height: 703px;
    top: 150px;
    left: 25%;
    z-index: 999;

    .wx_ti {
      background: url("../../assets/images/pop/wx_ti.png") no-repeat center;
      position: absolute;
      width: 187px;
      height: 65px;
      top: 3%;
      left: 23%;
      z-index: 999;
    }

    .wx_qrcode {
      background: url("../../assets/images/mpp/mini.jpg") no-repeat center;
      position: relative;
      top: 40%;
      height: 49%;
      width: 36%;
      left: 32%;
      border: 2px solid #31807B;
    }

    .close_wx {
      position: absolute;
      width: 6.6%;
      height: 8.6%;
      top: 16%;
      left: 80%;
      z-index: 999;
    }

    .wx_button_top {
      position: absolute;
      color: #516f6d;
      top: 28%;
      /* border-bottom: 1px solid; */
      left: 35%;
      font-size: 25px;
    }
  }

  .btnItems_Select {
    background: url("../../assets/images/pop/game_btn.png") no-repeat center;
  }

  .tab {
    color: #846115;
    position: relative;
    margin-top: 15px;
    height: 75px;
    font-size: 30px;
    text-align: center;
  }

  .line {
    position: relative;
    margin-top: 200px;
    text-align: center;
    margin-left: 70px;
  }

  .phone {
    display: flex;
    height: 75px;
    top: 50px;
    position: relative;

  }

  .phone_input {
    background: url("../../assets/images/pop/phone_bg.png") no-repeat center;
    width: 541px;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, .5);
  }

  .phone_zi {
    color: #516f6d;
    position: relative;
    margin-left: 100px;
    margin-top: 15px;
    height: 75px;
    font-size: 36px;
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    font-size: 36px;
  }


  input {
    -moz-appearance: textfield;
    font-size: 36px;
  }

  input::placeholder {
    text-align: center;
  }

  //针对谷歌浏览器的设置方式：
  input::-webkit-input-placeholder {
    font-size: 36px;
  }

  //针对火狐浏览器的设置方式：
  input::-moz-placeholder {
    font-size: 36px;
  }

  //针对IE浏览器的设置方式：
  input:-ms-input-placeholder {
    font-size: 36px;
  }


  .pop_Video {
    display: none;
    height: 100%;
    position: absolute;
    top: 2310px;
    margin-left: 380px;
    z-index: 999;

    .closeVideo {
      position: absolute;
      width: 70px;
      height: 80px;
      //top: 130px;
      left: 904px;
      z-index: 999;

    }
  }
}
</style>