import axios from 'axios'

export function request(config) {
    const instance = axios.create({
        //baseURL: 'http://192.168.0.122:19010'
        //baseURL: 'https://t-wegame.legendtrip.com/web/api'
        // baseURL: 'https://hkgw.yaolinggame.com/web/api'
        // baseURL: window.location.origin + '/web/api'
        baseURL: window.location.origin + '/web/api'
        // baseURL: 'http://127.0.0.1:19000'


    })
    return instance(config)
}

export function changeTabName(tab) {
    if (tab == "新闻") {
        return "问答"
    }
    return tab
}

export function changeTabNameBack(tab) {
    if (tab == "问答") {
        return "新闻"
    }
    return tab
}