<template>
  <div id="app">
    <compNavigation></compNavigation>
    <router-view></router-view>
    <compFooter></compFooter>
  </div>
</template>

<script>
import compNavigation from "./components/Navigation";
import compFooter from "./components/Footer";

export default {
  name: 'App',
  components: {
    compNavigation,
    compFooter
  }
}
</script>

<style>
@import "assets/css/main.css";
</style>
